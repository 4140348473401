<template>
  <div class="page page__content-wrapper">
    <div class="page__full-width">
      <page-item v-for="(item, idx) in data" :content="item" :key="idx"></page-item>
    </div>

    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import PageItem from "@/components/Elements/PageItem/PageItem"

export default {
  components: {
    PageItem,
    BottomNavBlock
  },

  data() {
    return {
      bottomNav: {
        pre: {
          url: "/brand/event-decoration",
          text: "Оформление мероприятий"
        },
        next: {
          url: "/brand/video-and-animation",
          text: "Видео и анимация"
        }
      },
      data: [
        {
          type: "image",
          images: [
            {
              img: "interior-page/interior-01.png",
              icons: [
                {
                  url: "../download-file/11-Interior/Steor-WallLogo.zip",
                  icon: "icon-ai.svg"
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/var-style";
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";
@import "../../../assets/style/buttons";

.page {
  margin-bottom: 192px;
  &__min-container {
    margin-bottom: 64px;
  }
}

///deep/.download-block {
//  margin-left: 24px;
//}

@media screen and (max-width: 1540px) {
  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 263px;
  }
}


@media screen and (max-width: 1320px) {
  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1216px) {
  .page__link-block.download-block {
    display: none;
  }
}

@media screen and (max-width: 1090px) {
  .page {
    margin-top: 0;
    padding-top: 18px;
  }
}

@media screen and (max-width: 896px) {
  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    margin-bottom: 161px;

    &__full-width {
      width: 100%;
    }

    &__download-block {
      display: none;
    }
  }
}

</style>
